import React from 'react';

import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';

import { auth, signInWithGoogle } from '../../firebase/firebase.utils';

import './sign-in.styles.scss';
import { Link } from 'react-router-dom';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: false,
    }
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({ [name]: value });
  }
  
  handleSubmit = async e => {
    e.preventDefault();
    
    const { email, password } = this.state;

    if (!email || !password) {
      this.setState({ error: 'Informe seu email e senha para entrar.'});
      return;
    }

    try {
      await auth.signInWithEmailAndPassword(email, password);
      this.setState({ email: '', password: '' });
    } 
    catch (error) {
      switch(error.code) {
        case "auth/user-not-found":
          this.setState({ error: 'Nenhum usuário encontrado com este email.' });
          break;
        case "auth/wrong-password":
          this.setState({ error: 'Senha incorreta. Você pode ter entrado com o Google.' });
          break;
        default:
          this.setState({ error: error.message });
      }
    }
  }

  render () {
    const { handleRegister } = this.props;
    const { email, password, error } = this.state;
    
    return (
      <div className="sign_in">
        <h2 className="title">Já tenho uma conta</h2>
        <span>Faça login com seu email e senha</span>
        
        {error ? (
          <p className="sign_error">* {error}</p>
        ): null}

        <form className="sign_in_form" onSubmit={this.handleSubmit}>
          <FormInput
            required
            type="email"
            label="Email"
            value={email}
            name="email"
            autoComplete="username"
            handleChange={this.handleChange}
          />
          
          <FormInput 
            type="password"
            value={password}
            label="Senha"       
            name="password"
            onChange={this.handleChange}
            autoComplete="current-password"
          />

          <div className="sign_in_form_buttons">
            <Button type="submit">Entrar</Button>
            <Button type="button" onClick={signInWithGoogle} isGoogleSignIn>
              Entrar com Google
            </Button>
          </div>

          <div className="signin_forgot_password">
            <Link to="/forgotten">Esqueci minha senha</Link>
          </div>

          <div className="sign_up_button">
            Não tem uma conta? <span onClick={handleRegister}>Criar Conta</span>
          </div>
        </form>
      </div>
    )
  }
}

export default SignIn;