import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectOpenSearchModal } from '../../redux/modal/modal.selectors';

import CheckoutFloatingButton from '../checkout-floating-button/checkout-floating-button.component';
import Footer from '../../components/footer/footer.component';
import Header from '../../components/header/header.component';
import Modal from '../../components/modal/modal.component';
import ModalNavigation from '../../components/modal-navigation/modal-navigation.component';
import ModalSearchBox from '../../components/modal-search-box/modal-search-box.component';
import NavigationFixed from '../../components/navigation-fixed/navigation-fixed.component';
import SideNavigation from '../../components/side-navigation/side-navigation.component';

import './layout.styles.scss';

const Layout = ({children, CheckoutButton = true, ...props}) => {
  const { openSearchModal } = props;

  return (
    <>
      <div className="layout_grid">
        <div className="layout_topo">
          <Header />
        </div>
        
        <div className="layout_lateral">
          <SideNavigation />
        </div>
        
        <div className="layout_content">
          <div className="layout_content_container">
            {children}
          </div>
          <Footer />
        </div>
        
        {CheckoutButton ? <CheckoutFloatingButton /> : null}
        
        <Modal>
          {openSearchModal ? <ModalSearchBox /> : <ModalNavigation />}
        </Modal>
      </div>

      <div className="layout_bottom">
        <NavigationFixed />
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  openSearchModal: selectOpenSearchModal,
})

export default connect(mapStateToProps)(Layout);