import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Layout from "../../components/layout/layout.component";
import "./not-found-page.styles.scss";

const NotFoundPage = () => (
  <Layout>
    <div className="notfoundpage">
      <h2>Ops, algo deu errado!</h2>
      <p>Não encontramos o que você está procurando.</p>
      <span className="notfoundpage_button">
        <Link to="/">
          <Icon>home</Icon>Ir para o início
        </Link>
      </span>
    </div>
  </Layout>
);

export default NotFoundPage;
