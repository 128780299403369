import React from 'react';

import Layout from '../../components/layout/layout.component';
import SignIn from '../../components/sign-in/sign-in.component';
import SignUp from '../../components/sign-up/sign-up.component';

import './signin-and-signup.styles.scss';

const SignInAndSignUpPage = () => {
  const [ login, setLogin ] = React.useState(true);

  const handleRegister = () => {
    setLogin(false);
  }
  
  const handleLogin = () => {
    setLogin(true);
  }

  return (
    <Layout>
      <div className="signIn_signUp">
        {login ? (
          <SignIn handleRegister={handleRegister} />
        ) : (
          <SignUp handleLogin={handleLogin} />
        )}
      </div>
    </Layout>
  )
}

export default SignInAndSignUpPage;