import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Layout from "../../components/layout/layout.component";
import "./success.styles.scss";

const SuccessPage = ({ sessionId }) => {
  return (
    <Layout>
      <div className="successpage">
        <h2 className="successpage_header">
          <Icon>check_circle</Icon> Compra finalizada
        </h2>
        <div className="successpage_message">
          <p className="successpage_order">
            Número do pedido: <b>{sessionId}</b>
          </p>
          <hr />
          <p className="successpage_paragraph">
            Obrigada pela iniciativa de reproduzir artesanatos fáceis, baratos e
            com muita economia de materiais. Gratidão pelo seu apoio 😊
          </p>
          <strong className="successpage_paragraph">
            Após o sistema confirmar o pagamento, seu download é liberado
            automaticamente em{" "}
            <Link to="/account" className="text_highlight">
              Minha Conta
            </Link>
            .
          </strong>
          <p className="successpage_paragraph">
            Clique no botão abaixo, para ver seus downloads.
          </p>
          <span className="successpage_button">
            <Link to="/download">
              <Icon>file_download</Icon>
              Meus Downloads
            </Link>
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default SuccessPage;
