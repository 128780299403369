import { Link } from 'react-router-dom';

import './logotipo.styles.scss';

const LOGO = process.env.REACT_APP_LOGO;

const Logotipo = () => (
  <div className="logotipo">
    <Link to="/">
      <img src={LOGO} alt="CRIANDO COM A HELÔ"/>
      <h1>CRIANDO <br />COM A HELÔ</h1>
    </Link>
  </div>
)

export default Logotipo;