import NavigationButton from "../navigation-button/navigation-button.component";

import "./side-navigation.styles.scss";

const SideNavigation = () => (
  <aside className="side_navigation">
    <div className="side_navigation_menu">
      <h2 className="side_navigation_title">Acesso rápido</h2>
      {/* <NavigationButton location="/" icon="home" name="início" />  */}
      <NavigationButton location="/" icon="get_app" name="moldes" />
    </div>

    <div className="side_navigation_footer">
      <NavigationButton
        location="/account"
        icon="account_circle"
        name="minha conta"
      />
      <NavigationButton
        location="#"
        mailto="mailto:contato@criandocomahelo.com"
        icon="comment"
        secondary
        name="contato"
      />
    </div>
  </aside>
);

export default SideNavigation;
