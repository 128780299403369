import SearchBox from '../search-box/search-box.component';

import './modal-search-box.styles.scss';

const ModalSearchBox = () => (
  <div className="modal_search_box">
    <h3 className="modal_search_box_title">Busca</h3>
    <SearchBox placeholder="Busque aqui ..." />
  </div>
)

export default ModalSearchBox;