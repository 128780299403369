import React from 'react';

import { auth, createUserProfileDocument } from '../../firebase/firebase.utils';

import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';

import './sign-up.styles.scss';

class SignUp extends React.Component {
  constructor() {
    super();

    this.state = {
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
      photoURL: 'https://www.gstatic.com/images/branding/product/2x/avatar_square_blue_120dp.png',
      error: false,
    }
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async e => {
    e.preventDefault();

    const {
      displayName,
      email, 
      password,
      confirmPassword,
      photoURL
    } = this.state;

    if (password !== confirmPassword) {
      this.setState({ error: 'Senhas não correspondem' })
      return;
    }

    try {
      const {user} = await auth.createUserWithEmailAndPassword(email, password);

      await createUserProfileDocument(user, { displayName, photoURL });

      this.setState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: '',
      })
    }
    catch (error) {
      switch(error.code) {
        case 'auth/weak-password':
          this.setState({ error: 'Senha fraca. A senha deve ter pelo menos 6 caracteres'})
          break;
        default:
          this.setState({ error: error.message })
      }
    }
  }

  render() {
    const { handleLogin } = this.props;
    
    const { 
      displayName, 
      email, 
      password, 
      confirmPassword, 
      error
    } = this.state;

    return (
      <div className="sign_up">
        <h2 className="title">Não tenho uma conta</h2>
        <span>Registre-se com seu email e senha</span>

        {error ? (
          <p className="sign_error">* {error}</p>
        ): null}

        <form className="sign_up_form" onSubmit={this.handleSubmit}>
          <FormInput 
            required
            type="text"
            label="Nome"
            name="displayName"
            value={displayName}
            handleChange={this.handleChange}
          />
          <FormInput
            required
            type="email"
            label="Email"
            name="email"
            value={email}
            autoComplete="username"
            handleChange={this.handleChange}
          />
          <FormInput 
            required
            type="password"
            label="Senha"
            name="password"
            value={password}
            autoComplete="new-password"
            handleChange={this.handleChange}
          />
          <FormInput
            required
            type="password"
            label="Confirmar Senha"
            name="confirmPassword"
            value={confirmPassword}
            autoComplete="new-password"
            handleChange={this.handleChange}
          />

          <div className="sign_up_form_buttons">
            <Button type="submit">Criar conta</Button>

            <div className="sign_in_button">
              Já tem uma conta? <span onClick={handleLogin}>Entrar</span>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default SignUp;