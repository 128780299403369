export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItems = cartItems.find(
    cartItem => cartItem.id === cartItemToAdd.id
  );

  if (existingCartItems) {
    return cartItems.map(cartItem =>
      cartItem.id === cartItemToAdd.id 
      ? { ...cartItem, quantity: cartItem.quantity + 1 }
      : cartItem
    );
  }

  return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
}

/**
 * This function decreases cart item quantity
 * @param {*} cartItems all cart items
 * @param {*} cartItemToRemove item to decrease
 * @returns an updated array of cart items
 */
export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItems = cartItems.find(
    cartItem => cartItem.id === cartItemToRemove.id
  );

  if (existingCartItems.quantity === 1) {
    return cartItems.filter(
      cartItem => cartItem.id !== cartItemToRemove.id
    )
  }

  return cartItems.map(
    cartItem => cartItem.id === cartItemToRemove.id
    ? { ...cartItem, quantity: cartItem.quantity - 1 }
    : cartItem
  )
}