import NavigationButton from "../navigation-button/navigation-button.component";

import "./modal-navigation.styles.scss";

const ModalNavigation = () => {
  return (
    <div className="modal_navigation">
      <NavigationButton location="/" icon="home" inverted name="início" />
      <NavigationButton location="/" icon="get_app" inverted name="moldes" />
      <NavigationButton
        location="#"
        icon="comment"
        secondary
        inverted
        mailto="mailto:contato@criandocomahelo.com"
        name="contato"
      />
    </div>
  );
};

export default ModalNavigation;
