import { connect } from 'react-redux';

import { selectCartItemsCount } from '../../redux/cart/cart.selectors';

import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';

import './checkout-floating-button.styles.scss';

const CheckoutFloatingButton = ({ itemCount }) => (
  <Link className="cart_floating_button_container" to="/checkout">
    <div className="cart_floating_button">
      <Icon>shopping_cart</Icon>
    </div>
    <div className="cart_floating_button_tag">{itemCount}</div>
  </Link>
)

const mapStateToProps = state => ({
  itemCount: selectCartItemsCount(state),
})

export default connect(mapStateToProps)(CheckoutFloatingButton);