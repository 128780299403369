import Logotipo from '../logotipo/logotipo.component';
import LoginButton from '../login-button/login-button.component';
import SearchBox from '../search-box/search-box.component';
import CheckoutButton from '../checkout-button/checkout-button.component';

import './header.styles.scss';

const Header = () => (
  <div className="header">
    <Logotipo />
    <SearchBox placeholder="Busque o que está procurando ..." />
    <CheckoutButton />
    <LoginButton />
  </div>
);

export default Header;