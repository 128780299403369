import { UserActionTypes } from './user.types';

export const setCurrentUser = user => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user
});

export const userCameFromCheckout = user => ({
  type: UserActionTypes.USER_CAME_FROM_CHECKOUT,
  payload: user
});