import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import './card-tag.styles.scss';

const CardTag = ({ icon, right, secondary, children }) =>{

  const position = right ? "card_tag_top_right" : "card_tag_top_left";

  return (
    <div className={clsx("card_tag", position, {
      'secondary_background': secondary, 
      'primary_background': !secondary
      })}
    >
      {icon ? <Icon>{icon}</Icon> : null}
      {children}
    </div>
  )
}

export default CardTag;