import { createSelector } from 'reselect';

const selectProduct = state => state.product;

export const selectProducts = createSelector(
  [selectProduct],
  product => product.products
);

export const selectIsProductsFetching = createSelector(
  [selectProduct],
  product => product.isFetching
);

export const selectProductsLastAdded = createSelector(
  [selectProducts],
  products => products.sort(function (a, b) {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    // a must be equal to b
    return 0;
  })
);
