import React from "react";
import { Link } from 'react-router-dom';
import { auth } from "../../firebase/firebase.utils";
import FormInput from "../form-input/form-input.component";
import Button from "../button/button.component";

import "./forgot-password.styles.scss";

const ForgotPassword = () => {
  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    auth.sendPasswordResetEmail(email)
      .then(() => {
        setError("Email enviado com sucesso!");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div className="sign_in">
      <h2 className="title">Esqueci minha senha</h2>
      <span>Informe seu email cadastrado na loja. Você vai receber no seu email um link para redefinir sua senha.</span>

      {error ? <p className="sign_error">* {error}</p> : null}

      <form className="sign_in_form" onSubmit={handleSubmit}>
        <FormInput
          required
          type="email"
          label="Email"
          value={email}
          name="email"
          autoComplete="username"
          handleChange={handleChange}
        />
        <div className="sign_in_form_buttons">
          <Button type="submit">Enviar</Button>
        </div>
        <div className="sign_up_button">
          Retornar para o <span><Link to="/signin">Login</Link></span>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
