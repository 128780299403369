import React from "react";
import Icon from "@material-ui/core/Icon";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectSearchFilter } from "../../redux/search/search.selectors";
import { selectProductsLastAdded } from "../../redux/product/product.selectors";

import Layout from "../../components/layout/layout.component";
import GridProducts from "../../components/grid-products/grid-products.component";

import "./homepage.styles.scss";

class HomePage extends React.Component {
  render() {
    const { products, searchFilter } = this.props;

    const filteredProducts = products.filter((product) =>
      product.name.toLowerCase().includes(searchFilter.toLowerCase())
    );

    return (
      <Layout>
        {products.length < 1 && <p className="homepageMessage">Carregando..</p>}

        {products.length > 0 && filteredProducts.length < 1 ? (
          <div className="homepageMessage">
            <Icon>search</Icon>&ensp;
            <p>Não encontrei nenhum produto com nome&ensp;</p>
            <b>"{searchFilter.toUpperCase()}".</b>
          </div>
        ) : (
          <GridProducts products={filteredProducts} />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  products: selectProductsLastAdded,
  searchFilter: selectSearchFilter,
});

export default connect(mapStateToProps)(HomePage);
