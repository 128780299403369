import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';

import { connect } from 'react-redux';
import { toggleModalHidden } from '../../redux/modal/modal.actions';

import { createStructuredSelector } from 'reselect';
import { selectModalHidden } from '../../redux/modal/modal.selectors';

import './modal.styles.scss';

const Modal = ({ children, ...props }) => {

  const { hidden, toggleModalHidden } = props;

  return (
    <div className={clsx('modal', {'close' : hidden})}>
      <div 
        className="modal_close_button" 
        onClick={() => toggleModalHidden()}
      >
        <Icon>close</Icon>Fechar
      </div>
      <div className="modal_content">
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  hidden: selectModalHidden,
});

const mapDispatchToProps = dispatch => ({
  toggleModalHidden: () => dispatch(toggleModalHidden())
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);