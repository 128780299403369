import firebase from 'firebase/app';
import "firebase/firestore";
import 'firebase/storage';
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig);

const storageRef = firebase.storage().ref();

export const auth = firebase.auth();

export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email, photoURL } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        createdAt,
        ...additionalData
      });
    } catch (error) {
      console.log('error creating user', error.message);
    }
  }

  return userRef;
}

export const storageDownloadFile = (fileName) => {
  const moldeRef = storageRef.child('download/molde');
  const itemRef = moldeRef.child(fileName);

  // This can be downloaded directly
  itemRef.getDownloadURL().then(url => {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = event => {
      let blob = xhr.response;
      saveOrOpenBlob(blob, fileName);
    };
    xhr.open('GET', url);
    xhr.send();
  }).catch(error => {
    switch (error.code) {
      case 'storage/object-not-found':
        console.log('File doesnt exist');
        break;
      case 'storage/unauthorized':
        console.log('User doesnt have permission to access the object');
        break;
      case 'storage/unknown':
        console.log('Unknown error occurred, inspect the server response');
        break;
      default:
        console.log(error.message);
    }
  });
}

const saveOrOpenBlob = (blob, fileName) => {
  var temp = document.createElement("a");
  document.body.appendChild(temp);
  temp.style = "display: none";
  let objectURL = window.URL.createObjectURL(blob);
  temp.href = objectURL;
  temp.download = fileName;
  temp.click();
  window.URL.revokeObjectURL(objectURL);
}

export default firebase;