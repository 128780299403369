import clsx from 'clsx';

import './form-input.styles.scss';

const FormInput = ({ handleChange, label, ...otherProps }) => {
  return(
    <div className="form_group">
      <input 
        className="form_input"
        onChange={handleChange}
        {...otherProps}
      />

      {label ? (
        <label
          className={clsx('form_input_label', {
            'shrink': otherProps.value.length
          })}
        >
          {label}
        </label>
      ): null}
    </div>
  )
}

export default FormInput;