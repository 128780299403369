import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { selectCartItemsCount } from '../../redux/cart/cart.selectors';

import Icon from '@material-ui/core/Icon';

import './checkout-button.styles.scss';

const CheckoutButton = ({ itemCount }) => (
  <div className="cart_button">
    <Icon>shopping_cart</Icon>
    <div>
      <p>{itemCount} itens</p>
      <Link to="/checkout">comprar agora</Link>
    </div>
  </div>
)

const mapStateToProps = state => ({
  itemCount: selectCartItemsCount(state)
})

export default connect(mapStateToProps)(CheckoutButton);