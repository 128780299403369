import { UserActionTypes } from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  userCameFromCheckout: false,
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      };
    case UserActionTypes.USER_CAME_FROM_CHECKOUT:
      return {
        ...state,
        userCameFromCheckout: action.payload
      }
    default:
      return state;
  }
}

export default userReducer;