import { connect } from "react-redux";
import { addItem } from "../../redux/cart/cart.actions";

import CardTag from "../card-tag/card-tag.component";
import CardButton from "../card-button/card-button.component";

import "./card.styles.scss";

const Card = ({ item, addItem, children }) => {
  const { name, price, discount, imageUrl } = item;

  return (
    <>
      {discount ? (
        <div className="card card_offer">
          {children ? children : <CardTag>Promoção</CardTag>}
          <div className="card_image">
            <img src={imageUrl} alt="Item" />
          </div>
          <CardButton
            onClick={() => addItem(item)}
            discount={discount}
            price={price}
          />
        </div>
      ) : (
        <div className="card">
          {children ? children : null}
          <div className="card_image">
            <img src={imageUrl} alt="Item" />
            <p>{name}</p>
          </div>
          <CardButton onClick={() => addItem(item)} price={price} />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});

export default connect(null, mapDispatchToProps)(Card);
