import ProductActionTypes from './product.types';
import { firestore } from '../../firebase/firebase.utils';

export const fetchProductsStart = () => ({
  type: ProductActionTypes.FETCH_PRODUCTS_START
});

export const fetchProductsSuccess = productsArray => ({
  type: ProductActionTypes.FETCH_PRODUCTS_SUCCESS,
  payload: productsArray
});

export const fetchProductsFailure = errorMessage => ({
  type: ProductActionTypes.FETCH_PRODUCTS_FAILURE,
  payload: errorMessage
});

export const fetchProductsStartAsync = () => {
  return dispatch => {
    const productsRef = firestore.collection('products')
      .where('isDigital', '==', true);

    dispatch(fetchProductsStart());

    let unsubscribe = productsRef.onSnapshot(snapshot => {
      let products = [];
      snapshot.forEach(doc => {
        let timestamp = doc._delegate._document.version.timestamp.seconds;
        products.push({
          id: doc.id,
          created_at: timestamp,
          ...doc.data()
        });
      });
      dispatch(fetchProductsSuccess(products));
    }, (error) => {
      dispatch(fetchProductsFailure(error.message));
    });

    return unsubscribe;
  }
}