import clsx from 'clsx';

import {ReactComponent as GoogleIcon} from '../../assets/svg/google.svg';

import './button.styles.scss';

const Button = ({ children, isGoogleSignIn, ...otherProps }) => (
  <button 
    className={clsx('button', {
      'google_sign_in': isGoogleSignIn
    })}
    {...otherProps}
  >
    {isGoogleSignIn ? <GoogleIcon /> : null}
    {children}
  </button>
)

export default Button;