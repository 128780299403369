import React from "react";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectOrders,
  selectIsOrdersFetching,
} from "../../redux/order/order.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { fetchPaidOrdersFromUser } from "../../redux/order/order.actions";
import { storageDownloadFile } from "../../firebase/firebase.utils";
import Layout from "../../components/layout/layout.component";

import "./download.styles.scss";

class AccountPage extends React.Component {
  unsubFromOrders = null;

  componentDidMount() {
    const { user, fetchPaidOrdersFromUser } = this.props;
    this.unsubFromOrders = fetchPaidOrdersFromUser(user.id);
  }

  componentWillUnmount() {
    this.unsubFromOrders();
  }

  getDownloads = (orders) => {
    let downloads = [];
    orders.map((order) => {
      let products = order.line_items.filter(this.isDigital);
      products.map((product) => downloads.push(product));
    });
    return this.uniqueKey(downloads, (downloads) => downloads.id);
  };

  isDigital = (item) => {
    const { isDigital } = item;
    const type = typeof isDigital;

    switch (type) {
      case "string":
        if (isDigital.match(/true/g)) return item;
        break;
      case "boolean":
        if (Boolean(isDigital)) return item;
        break;
    }
  };

  uniqueKey = (a, key) => {
    let seen = new Set();
    return a.filter((item) => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  };

  render() {
    const { orders, isFetching } = this.props;
    const downloads = this.getDownloads(orders);

    const handleDownload = (fileName) => {
      storageDownloadFile(fileName);
    };

    return (
      <Layout>
        <div className="downloadContainer">
          <div className="downloadHeader">
            <small>
              <Link to="/">Início</Link>&emsp;&#x276F;&emsp;
              <Link to="/account">Minha conta</Link>&emsp;&#x276F;&emsp; Meus
              downloads
            </small>
            <h2>Meus Downloads</h2>
          </div>

          {isFetching ? (
            <p className="downloadMessage">Carregando..</p>
          ) : downloads.length < 1 ? (
            <div className="downloadMessage">
              <p>Você não tem nenhum item para download!</p>
              <p className="downloadMessageIcon">
                <Icon>add_shopping_cart</Icon>
                <Link to="/">Ver produtos</Link>
              </p>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Baixar</th>
                </tr>
              </thead>
              <tbody>
                {downloads.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>
                        <div
                          className="downloadButton"
                          onClick={() => handleDownload(item.download)}
                        >
                          Fazer download
                          <Icon>file_download</Icon>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  orders: selectOrders,
  user: selectCurrentUser,
  isFetching: selectIsOrdersFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaidOrdersFromUser: (userId) =>
    dispatch(fetchPaidOrdersFromUser(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
