import Card from '../card/card.component';

import './grid-products.styles.scss';

const GridProducts = ({ products }) => {
  return (
    <div className="grid_products">
      {
        products.map(item => (
          <Card key={item.id} item={item} />
        ))
      }
    </div>
  )
}

export default GridProducts;