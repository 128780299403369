import NumberFormat from 'react-number-format';
import Icon from '@material-ui/core/Icon';

import './card-button.styles.scss';

const CardButton = ({ price, discount, ...otherProps }) =>(
  <div {...otherProps}>
    {discount ? (
      <div className="card_button_offer">
        <div className="card_price_offer">
          <NumberFormat 
            className="card_discount"
            value={discount}
            prefix="&#8595;&nbsp;"
            suffix={"%"}
            decimalScale={0} 
            displayType={"text"}
            isNumericString={true}
            fixedDecimalScale={true}
          />
          <NumberFormat 
            className="card_price"
            value={price}
            prefix={'R$ '}
            decimalScale={2} 
            displayType={"text"}
            decimalSeparator={","}
            isNumericString={true}
            fixedDecimalScale={true}
          />
        </div>
        <div className="card_button">eu quero!</div>
      </div>
    ) : (
      <div className="card_button_normal">
        <div className="card_button"><Icon>shopping_cart</Icon>comprar</div>
        <NumberFormat 
            className="card_price"
            value={price}
            prefix={'R$ '}
            decimalScale={2} 
            displayType={"text"}
            decimalSeparator={","}
            isNumericString={true}
            fixedDecimalScale={true}
          />
      </div>
    )}
  </div>
)

export default CardButton;