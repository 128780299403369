import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { userCameFromCheckout } from "../../redux/user/user.actions";
import { auth } from "../../firebase/firebase.utils";

import {
  selectCurrentUser,
  selectIsUserCameFromCheckout,
} from "../../redux/user/user.selectors";

import "./login-button.styles.scss";

const LoginButton = ({ currentUser, isUserCameFromCheckout, dispatch }) => {
  const handleLogout = () => {
    auth.signOut();

    if (isUserCameFromCheckout) {
      dispatch(userCameFromCheckout(false));
    }
  };

  return (
    <>
      {currentUser ? (
        <div className="login_button">
          <Link className="login_dropdown_button" to="/account">
            <img src={currentUser.photoURL} alt="" />
            Minha conta <Icon>keyboard_arrow_down</Icon>
          </Link>

          <div className="login_dropdown_content">
            <Link to="/account">Perfil</Link>
            <Link to="/" onClick={handleLogout}>
              Sair
            </Link>
          </div>
        </div>
      ) : (
        <div className="login_button">
          <div className="login_button_sign_in">
            <Link to="/signin">
              <img
                src="https://www.gstatic.com/images/branding/product/2x/avatar_square_blue_120dp.png"
                alt=""
              />
              Entrar
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isUserCameFromCheckout: selectIsUserCameFromCheckout,
});

export default connect(mapStateToProps)(LoginButton);