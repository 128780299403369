import OrderActionTypes from './order.types';
import { firestore } from '../../firebase/firebase.utils';

export const fetchOrdersStart = () => ({
  type: OrderActionTypes.FETCH_ORDERS_START
});

export const fetchOrdersSuccess = ordersArray => ({
  type: OrderActionTypes.FETCH_ORDERS_SUCCESS,
  payload: ordersArray
});

export const fetchOrdersFailure = errorMessage => ({
  type: OrderActionTypes.FETCH_ORDERS_FAILURE,
  payload: errorMessage
});

export const fetchPaidOrdersFromUser = userId => {
  return dispatch => {
    const ordersRef = firestore.collection('orders').where('client_reference_id', '==', userId).where('payment_status', '==', 'paid');
    
    dispatch(fetchOrdersStart());

    const unsubscribe = ordersRef.onSnapshot(snapshot => {
      let orders = [];
      snapshot.forEach(doc => {
        orders.push({
          id: doc.id,
          ...doc.data()
        });
      });
      dispatch(fetchOrdersSuccess(orders));
    }, (error) => {
      dispatch(fetchOrdersFailure(error.message));
    });
  
    return unsubscribe;
  }
}