import React from "react";
import axios from "axios";
import Icon from "@material-ui/core/Icon";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { createStructuredSelector } from "reselect";
import { userCameFromCheckout } from "../../redux/user/user.actions";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  selectCurrentUser,
  selectIsUserCameFromCheckout,
} from "../../redux/user/user.selectors";

import {
  selectCartItems,
  selectCartTotal,
  selectCartItemsCount,
} from "../../redux/cart/cart.selectors";

import Layout from "../../components/layout/layout.component";
import CheckoutItem from "../../components/checkout-item/checkout-item.component";
import Button from "../../components/button/button.component";

import "./checkout.styles.scss";

const stripeEnv = process.env.REACT_APP_ENV;
let publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY;

if (stripeEnv !== "production") {
  publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST_KEY;
}

console.log("[stripeEnv]", stripeEnv);
// console.log("[key]", publishableKey);

const stripePromise = loadStripe(publishableKey);

class CheckoutPage extends React.Component {
  state = {
    disabled: false,
    buttonText: "continuar e pagar",
  };

  componentDidMount() {
    this.setState({
      disabled: false,
      buttonText: "continuar e pagar",
    });

    this.whenCheckoutIsEmpty();
  }

  componentDidUpdate() {
    this.whenCheckoutIsEmpty();
  }

  /**
   * Se o usuário remover todos os produtos com esta condição userCameFromCheckout: true
   * Habilitada em algum momento, e ele for fazer login, seria redirecionado para o carrinho vazio
   * Quando não estiver itens no carrinho altere esse valor para false
   * Para que o usuário seja direcionado para a página account quando fizer login.
   */
  whenCheckoutIsEmpty = () => {
    const { countItems } = this.props;
    if (countItems < 1 && selectIsUserCameFromCheckout) {
      this.props.dispatch(userCameFromCheckout(false));
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ disabled: true, buttonText: "processando.." });

    /**
     * Caso o usuário não estiver logado e clicar no botão de checkout
     * Vai disparar o dispatch para alterar o valor de userCameFromCheckout para true
     * Isso previne que o usuário não faça checkout sem estar logado,
     * Garantindo que ele sempre tenha um id válido diferente de null sendo passado para o servidor!!!
     */
    const { currentUser, dispatch, history } = this.props;

    if (currentUser === null) {
      let waitHandleDispatch = new Promise((resolve, reject) => {
        dispatch(userCameFromCheckout(true));

        // Isto é apenas para criar assincronismo
        window.setTimeout(() => resolve(), 1500);
      });

      // Redireciona para o login
      waitHandleDispatch.then(() => history.push("/signin"));
    } else {
      this.handleStripeCheckout();
    }
  };

  handleStripeCheckout = async () => {
    const { currentUser, cartItems } = this.props;
    const stripe = await stripePromise; // Get Stripe.js instance
    const body = {
      customer: {
        id: currentUser.id,
        email: currentUser.email,
      },
      cartItems,
    };

    // Call backend to create the checkout session
    const { data: session } = await axios.post("/create-checkout-session", body);

    // When the customer clicks on the button, redirect them to Stripe checkout

    try {
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error("Error to redirect for stripe checkout", {
          user: currentUser,
          message: result.error.message,
        });
        alert(
          "Não conseguimos finalizar sua compra. Por favor, atualize a página e tente novamente."
        );
      }
    } catch (error) {
      console.error(
        "Falha na conexão. Não estamos conseguindo concluir seu pagamento, Por favor, tente em alguns minutos ou entre em contato por email: contato@criandocomahelo.com", {
          user: currentUser,
          message: error,
        }
      );
      alert(
        "Falha na conexão. Não estamos conseguindo concluir seu pagamento, \nPor favor, tente em alguns minutos ou entre em contato por email: contato@criandocomahelo.com"
      );
    }
  };

  render() {
    const { total, cartItems, countItems } = this.props;
    const { disabled, buttonText } = this.state;

    return (
      <Layout CheckoutButton={false}>
        <div className="checkout_page">
          <div className="checkout_header">
            <h2>Carrinho</h2>
            <p>Você tem {countItems} item(s) no seu carrinho</p>
          </div>

          {countItems > 0 ? (
            cartItems.map((cartItem) => (
              <CheckoutItem key={cartItem.id} cartItem={cartItem} />
            ))
          ) : (
            <span className="checkout_message">
              <Icon>add_shopping_cart</Icon> Seu carrinho está vazio,&nbsp;
              <Link to="/">adicione produtos!</Link>
            </span>
          )}

          <div className="checkout_total">
            <span>TOTAL:&emsp;</span>

            <NumberFormat
              value={total}
              prefix={"R$ "}
              decimalScale={2}
              displayType={"text"}
              decimalSeparator={","}
              isNumericString={true}
              fixedDecimalScale={true}
            />
          </div>

          {countItems > 0 ? (
            <div className="checkout_button">
              <Button onClick={this.handleClick} disabled={disabled}>
                {disabled ? (
                  <CircularProgress color="inherit" size="1.2rem" />
                ) : (
                  <Icon>verified_user</Icon>
                )}
                {buttonText}
                <Icon>arrow_forward_ios</Icon>
              </Button>
            </div>
          ) : null}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  total: selectCartTotal,
  cartItems: selectCartItems,
  countItems: selectCartItemsCount,
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(CheckoutPage);
