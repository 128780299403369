import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { auth, createUserProfileDocument } from './firebase/firebase.utils';
import { setCurrentUser } from './redux/user/user.actions';
import { fetchProductsStartAsync } from "./redux/product/product.actions";
import { selectCurrentUser, selectIsUserCameFromCheckout } from './redux/user/user.selectors';

import AccountPage from './pages/account/account.component';
import CheckoutPage from './pages/checkout/checkout.component';
import DownloadPage from './pages/download/download.component';
import ForgottenPage from './pages/forgotten/forgotten.component';
// import HistoryPage from './pages/history/history.component';
import HomePage from './pages/homepage/homepage.component';
import NotFoundPage from './pages/404/not-found-page.component';
import SuccessPage from './pages/success/success.component';
import SignInAndSignUpPage from './pages/signin-and-signup/signin-and-signup.component';

import './App.css';

class App extends React.Component {
  unsubFromAuth = null;
  unsubFromProductsSanapshot = null;

  componentDidMount() {
    this.unsubFromAuth = this.userAuthentication();
    this.unsubFromProductsSanapshot = this.props.fetchProductsStartAsync();
  }

  componentWillUnmount() {
    this.unsubFromAuth();
    this.unsubFromProductsSanapshot();
  }

  userAuthentication = () => {
    const { setCurrentUser } = this.props;

    return auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);
        userRef.onSnapshot(snapShot => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data()
          });
        });
      } else {
        setCurrentUser(userAuth);
      }
    });
  }

  render() {
    const { currentUser, isUserCameFromCheckout } = this.props;

    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route path="/forgotten" component={ForgottenPage} />
        <Route
          path="/signin"
          render={() =>
            currentUser ? (
              isUserCameFromCheckout ? (
                <Redirect to="/checkout" />
              ) : (
                <Redirect to="/account" />
              )
            ) : (
              <SignInAndSignUpPage />
            )
          }
        />

        <Route
          path="/account"
          render={() =>
            currentUser ? <AccountPage /> : <Redirect to="/signin" />
          }
        />

        <Route
          path="/download"
          render={() =>
            currentUser ? <DownloadPage /> : <Redirect to="/signin" />
          }
        />

        {/* <Route
          path="/history"
          render={() =>
            currentUser ? <HistoryPage /> : <Redirect to="/signin" />
          }
        /> */}

        <Route
          path="/success"
          render={() => <QueryParams />}
        />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    );
  }
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const QueryParams = () => {
  let query = useQuery();
  let isValid = query.get("session_id");

  console.log('QUERY: ', isValid);
  
  return (
    isValid ? <SuccessPage sessionId={isValid} /> : <Redirect to="/" />
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isUserCameFromCheckout: selectIsUserCameFromCheckout,
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user)),
  fetchProductsStartAsync: () => dispatch(fetchProductsStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App);