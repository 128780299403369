import OrderActionTypes from './order.types';

const INITIAL_STATE = {
  orders: [],
  isFetching: false,
  errorMessage: undefined,
}

const orderReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case OrderActionTypes.FETCH_ORDERS_START:
      return {
        ...state,
        isFetching: true
      }
    case OrderActionTypes.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orders: action.payload
      }
    case OrderActionTypes.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      }
    default:
      return state;
  }
}

export default orderReducer;