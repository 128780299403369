import NumberFormat from 'react-number-format';

import { connect } from 'react-redux';
import { addItem, removeItem, clearItemFromCart } from '../../redux/cart/cart.actions';

import './checkout-item.styles.scss';

const CheckoutItem = ({ cartItem, addItem, removeItem, clearItem }) => {
  const { name, imageUrl, price, quantity } = cartItem;
  
  return(
    <div className="checkout_item">
      <div className="image_container">
        <img src={imageUrl} alt="Item"/>
      </div>

      <div className="description_block">
        <span className="name">{name}</span>
        <NumberFormat 
          className="price"
          value={price} 
          prefix={'R$ '}
          decimalScale={2} 
          displayType={"text"}
          decimalSeparator={","}
          isNumericString={true}
          fixedDecimalScale={true}
        />
      </div>

      <span className="quantity">
        <div 
          className="arrow" 
          onClick={() => removeItem(cartItem)}
          title="(-) Diminuir Unidade"
        >
          &#10094;
        </div>
        <span className="value">{quantity}</span>
        <div 
          className="arrow" 
          onClick={() => addItem(cartItem)}
          title="(+) Aumentar Unidade"
        >
          &#10095;
        </div>
      </span>

      <div 
        className="remove_button" 
        onClick={() => clearItem(cartItem)}
      >
        &#10005;
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  addItem: item => dispatch(addItem(item)),
  removeItem: item => dispatch(removeItem(item)),
  clearItem: item => dispatch(clearItemFromCart(item)),
})

export default connect(null, mapDispatchToProps)(CheckoutItem);