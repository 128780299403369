import React from 'react';
import Icon from '@material-ui/core/Icon';

import { connect } from 'react-redux';
import { setSearchFilter } from '../../redux/search/search.actions';
import { toggleModalHidden } from '../../redux/modal/modal.actions';

import { createStructuredSelector } from 'reselect';
import { selectSearchFilter } from '../../redux/search/search.selectors';

import './search-box.styles.scss';

const SearchBox = (props) => {

  const { 
    placeholder, 
    searchFilter, 
    setSearchFilter, 
    toggleModalHidden, 
  } = props;

  const onSearchChange = e => {
    setSearchFilter(e.target.value);
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      toggleModalHidden()
    }
  }

  return (
    <div className="search_box">
      <button type="submit"><Icon>search</Icon></button>
      <input 
        type="search" 
        onChange={onSearchChange} 
        onKeyPress={handleKeyPress}
        placeholder={placeholder} 
        value={searchFilter} 
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  searchFilter: selectSearchFilter
})

const mapDispatchToProps = dispatch => ({
  setSearchFilter: search => dispatch(setSearchFilter(search)),
  toggleModalHidden: () => dispatch(toggleModalHidden())
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);