import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";

import "./navigation-button.styles.scss";

const NavigationButton = (props) => {
  const { location, inverted, mailto, icon, primary, secondary, name } = props;

  const handleClick = (e) => {
    e.preventDefault();
    window.location = mailto;
  };

  return (
    <div className="navigation_button">
      <Link
        to={location}
        className={inverted ? "inverted" : null}
        onClick={mailto ? handleClick : null}
      >
        <Icon
          className={clsx({
            primary_color: primary,
            secondary_color: secondary,
          })}
        >
          {icon}
        </Icon>
        <p>{name}</p>
      </Link>
    </div>
  );
};
export default NavigationButton;
