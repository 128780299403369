import React from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { toggleModalHidden, toggleOpenSearchModal } from '../../redux/modal/modal.actions';

import Icon from '@material-ui/core/Icon';

import './navigation-fixed.styles.scss';

const NavigationFixed = ({ toggleModalHidden, toggleOpenSearchModal }) => {  
  
  const handleOpen = e => {
    toggleModalHidden()
    toggleOpenSearchModal(e)
  }

  return (
    <nav className="navigation_fixed_container">
      <ul>
        <li>
          <Link to="/">
            <Icon>home</Icon>
            <p>Início</p>
          </Link>
        </li>
        <li onClick={() => handleOpen(true)}>
          <Icon>search</Icon>
          <p>Busca</p>
        </li>
        <li onClick={() => handleOpen(false)}>
          <Icon>apps</Icon>
          <p>Menu</p>
        </li>
        <li>
          <Link to="/">
            <Icon>file_download</Icon>
            <p>Moldes</p>
          </Link>
        </li>
        <li>
          <Link to="/account">
            <Icon>account_circle</Icon>
            <p>Perfil</p>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

const mapDispatchToProps = dispatch => ({
  toggleModalHidden: () => dispatch(toggleModalHidden()),
  toggleOpenSearchModal: modal => dispatch(toggleOpenSearchModal(modal)),
});

export default connect(null, mapDispatchToProps)(NavigationFixed);