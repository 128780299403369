import Icon from "@material-ui/core/Icon";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import Layout from "../../components/layout/layout.component";
import "./account.styles.scss";

const AccountPage = ({ user }) => {
  return (
    <Layout>
      <div className="accountContainer">
        <div className="accountHeader">
          <small>
            <Link to="/">Início</Link>&emsp;&#x276F;&emsp;Minha conta
          </small>
          <h2>Minha conta</h2>
          <p>
            Olá&nbsp;<b>{user.displayName}</b>,&nbsp;bem-vindo(a) a sua conta.
          </p>
        </div>

        <div className="accountContainerButtons">
          {/* <Link to="/history" className="accountButton">
            <Icon>history</Icon>
            Histórico de Pedidos
          </Link> */}
          <Link to="/download" className="accountButton">
            <Icon>file_download</Icon>
            Meus Downloads
          </Link>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});
export default connect(mapStateToProps)(AccountPage);
