import React from "react";

import { Link } from "react-router-dom";

import { ReactComponent as YoutubeIcon } from "../../assets/svg/youtube.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/svg/instagram.svg";
import { ReactComponent as PinterestIcon } from "../../assets/svg/pinterest.svg";
import { ReactComponent as DevelopedBadge } from "../../assets/svg/developed.svg";

import SSLImage from "../../assets/image/ssl.png";
import GoogleSafeImage from "../../assets/image/googleSafe.png";

import "./footer.styles.scss";

const Footer = () => {
  const mailto = "mailto:contato@criandocomahelo.com";
  
  const handleClick = (e) => {
    e.preventDefault();
    window.location = mailto;
  };

  return (
    <footer className="footer">
      <div className="footer_menu">
        <ul className="footer_social_icons">
          <li>
            <a
              href="https://www.youtube.com/criandocomahelo"
              target="_blank"
              title="YouTube"
            >
              <YoutubeIcon />
              <p>YouTube</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/criandocomahelo.oficial"
              target="_blank"
              title="Facebook"
            >
              <FacebookIcon />
              <p>Facebook</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/criandocomahelo_oficial"
              target="_blank"
              title="Instagram"
            >
              <InstagramIcon />
              <p>Instagram</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.pinterest.com/criandocomahelo"
              target="_blank"
              title="Pinterest"
            >
              <PinterestIcon />
              <p>Pinterest</p>
            </a>
          </li>
        </ul>

        <ul className="footer_button">
          <li>
            <Link to="#" onClick={handleClick}>
              Contato
            </Link>
          </li>
          {/* <li>
            <Link to="/">Entrega</Link>
          </li> */}
          <li>
            <Link to="/">Formas de Pagamento</Link>
          </li>
          <li>
            <Link to="/">Mapa do Site</Link>
          </li>
          <li>
            <Link to="/">Política e Termos</Link>
          </li>
        </ul>
      </div>

      <div className="footer_copyright">
        <p>© 2019–2021 CRIANDO COM A HELÔ. Todos os Direitos Reservados.</p>
        <div className="footer_selos">
          <img
            height="55px"
            src={GoogleSafeImage}
            alt="Site Seguro – Google Safe Browsing"
            title="Site Seguro – Google Safe Browsing"
          />
          <img
            height="55px"
            src={SSLImage}
            alt="Certificado Seguro SSL HTTPS"
            title="Certificado Seguro SSL HTTPS"
          />
          <DevelopedBadge
            height="36px"
            title="Designed and Coded by Polyana Pimenta"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
